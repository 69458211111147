import logo from '../../../../assets/images/header/logo.png';
import '../GeneralFooter.less';

import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';

const FooterFifthRow = () => {
    const navigate = useNavigate();

    const onTermsClick = (id: string) => {
        navigate(`/home/terms/${id}`);
    }

    return (
        <div className='fifth-row-container'>
            <div className='fifth-row-left'>
                <img alt={'logo'} className='logo-img' src={logo} onClick={() => { navigate('/home'); }} />
                <Divider className='divider' type='vertical' />
            </div>
            <div className='fifth-row-right'>
                <div className='top-row'>
                    <div className='items' onClick={() => { onTermsClick('rg') }}><FormattedMessage id={'FOOTER_RESPONSIBLE'} /></div>
                    <div className='items' onClick={() => { onTermsClick('aboutus') }}><FormattedMessage id={'FOOTER_ABOUT_US'} /></div>
                </div>
                <div className='bottom-row'>
                    <FormattedMessage id='FOOTER_COPYRIGHT' values={{
                        year: new Date().getFullYear().toString()
                    }} />
                </div>
            </div>
        </div>
    )
}

export default FooterFifthRow;