
import "./TopMenu.less";
import homeIcon from '../../../assets/images/header/icon-home.svg';
import mobileIcon from '../../../assets/images/header/icon-mobile.svg';
import menuHighlight from '../../../assets/images/menu-highlight-img.png';
import chevronDown from '../../../assets/images/icon-angle-down.png';
import { Divider } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { gameCategories } from "./TopMenuProviderList";
import { useNavigate } from "react-router-dom";
import { Key, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_GAME_URL } from "../../../pages/desktop/game-page/GamePage.gql";
import { SET_LOGIN_STATUS, useAuth } from "../../../hooks/auth/AuthContext";
import useSpinner from "../../../hooks/layout/useSpinner";
import LoginModal from "../modal/LoginModal/LoginModal";
import { SIGN_IN } from "../../../pages/desktop/auth/auth.gql";
import useNotification from "../../../hooks/layout/useNotification";
import { setTokenCookie } from "../../helpers/set-token-cookie.helper";

const TopMenu = () => {
    let timeout: any;
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const { authState, authDispatch } = useAuth();
    const navigate = useNavigate();
    const { setErrorNotification } = useNotification();
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (gameLoading || loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [gameLoading, loading]);

    useEffect(() => {
        if (data && data.signIn) {
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "gmf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "gmf-rt", r);
            setTokenCookie("gmf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            setLoginModalVisible(false);
            navigate('/home/dashboard/walletDeposit');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (signInError) {
            if (signInError.message?.indexOf("INVALID_LOGIN") > -1 || signInError.message?.indexOf("INVALID_PHONE_LOGIN") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_INVALID_LOGIN` }));
            } else if (signInError.message?.indexOf("ACCOUNT_LOCKED") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_ACCOUNT_LOCKED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError]);

    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            // if (popupWindow) {
            //     popupWindow.location.href = gameData.gameUrl.url;
            window.open(gameData.gameUrl.url, "gameTab");
            // }
        } else {
            // if (popupWindow) {
            //     popupWindow.close(); // eslint-disable-next-line react-hooks/exhaustive-deps
            // }
        }
    }, [gameData]);

    const _onMouseOver = (e: any) => {
        clearTimeout(timeout);
        document.getElementById('sports_menu')?.classList.add('submenu-open');
    }

    const _onMouseOut = (e: any) => {
        timeout = setTimeout(function () {
            document.getElementById('sports_menu')?.classList.remove('submenu-open');
        }, 1000);
    }

    const onVipClick = () => {
        window.open('https://mx8898.com/page/guest/vip_detail.jsp', 'window')
    }

    const onSportsClick = (sport: any) => {
        if (authState.isLogin) {
            if (sport.hasUrl) {
                getGameUrl({
                    variables: {
                        gameUrlInput: {
                            cMSGameMenuProviderId: sport.providerId,
                            platform: 'web',
                            lang: 'en-US',
                            isFreeGame: 'false',
                            gameCode: sport.gameCode
                        }
                    }
                });
            }
        } else {
            setLoginModalVisible(true);
        }
    }

    return (
        <>
            <div className="nav-container">
                <div className="nav-items-container">
                    <div className="nav-icon-container-home" onClick={() => { navigate('/home') }}/>
                    <Divider type="vertical" />
                    <div className="nav-icon-container">
                        <img alt={'mo'} className="nav-home-icon" src={mobileIcon} />
                    </div>
                    <Divider type="vertical" />
                    <div
                        className="item-container"
                        onMouseOver={window.innerWidth > 820 ? _onMouseOver : () => { }}
                        onMouseOut={window.innerWidth > 820 ? _onMouseOut : () => { }}
                    >
                        <FormattedMessage id='TOP_MENU_SPORTS' />
                        <img alt={'ch'} className="nav-menu-chevron" src={chevronDown} />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" />
                    <div className="item-container" onClick={() => { navigate('/home/casino') }}>
                        <FormattedMessage id='TOP_MENU_CASINO' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" />
                    <div className="item-container" onClick={() => { navigate('/home/fishing') }}>
                        <FormattedMessage id='TOP_MENU_FISH' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" />
                    <div className="item-container" onClick={() => { navigate('/home/slot') }}>
                        <FormattedMessage id='TOP_MENU_SLOTS' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" />
                    {/* <div className="item-container" onClick={() => { navigate('/home/lottery') }}>
                        <FormattedMessage id='TOP_MENU_LOTTERY' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div> */}
                    <Divider type="vertical" />
                    <div className="item-container" onClick={() => { navigate('/home/table') }}>
                        <FormattedMessage id='TOP_MENU_TABLE' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" />
                    <div className="item-container" onClick={() => { navigate('/home/promo') }}>
                        <FormattedMessage id='TOP_MENU_PROMO' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    {/* <Divider type="vertical" />
                    <div className="item-container" onClick={onVipClick}>
                        <FormattedMessage id='TOP_MENU_VIP' />
                        <img alt={'hi'} className="menu-highlight-img" src={menuHighlight} />
                    </div>
                    <Divider type="vertical" /> */}
                </div>
            </div>
            <div className="submenu" id='sports_menu'>
                <div className="submenu-wrapper">
                    {gameCategories.filter(x => x.id === 'sports')[0].provider.map((sport: { id: any; logo: any; }) => {
                        return (
                            <div className="provider-container" key={sport.id} onClick={() => { onSportsClick(sport) }}>
                                <img alt={'sl'} src={sport.logo} />
                                <div className="play-now"><FormattedMessage id='SUBMENU_PLAY_NOW' /></div>
                            </div>
                        );
                    })
                    }
                </div>
            </div>
            <LoginModal loading={loading} signIn={signIn} modalVisible={{ visible: loginModalVisible, setVisible: setLoginModalVisible }} />
        </>

    );
};

export default TopMenu;