import React from "react";
import NoMatch from "./NoMatch";

export interface MobileRouteInfo {
	path: string;
	component: any;
	routes?: MobileRouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const Register = ReactLazyPreload(() => import("./pages/_mobile/auth/register/RegisterMobile"));
Register.preload();

const Login = ReactLazyPreload(() => import("./pages/_mobile/auth/login/LoginMobile"));
Login.preload();

const MyAccount = ReactLazyPreload(() => import("./pages/_mobile/my-account/MyAccount"));
MyAccount.preload();

const ResetPassword = ReactLazyPreload(() => import("./pages/_mobile/reset-password/ResetPassword"));
ResetPassword.preload();

const Funds = ReactLazyPreload(() => import("./pages/_mobile/funds/Funds"));
Funds.preload();

const PersonalInfo = ReactLazyPreload(() => import("./pages/_mobile/personal-info/PersonalInfo"));
PersonalInfo.preload();

const Referral = ReactLazyPreload(() => import("./pages/_mobile/referral/ReferralMobile"));
Referral.preload();

const GamePage = ReactLazyPreload(() => import("./pages/_mobile/game-page/GamePageMobile"));
GamePage.preload();

const BettingRecords = ReactLazyPreload(() => import("./pages/_mobile/betting-records/BettingRecords"));
BettingRecords.preload();

const Promo = ReactLazyPreload(() => import("./pages/_mobile/promo/Promo"));
Promo.preload();

const TransactionRecords = ReactLazyPreload(() => import("./pages/_mobile/transaction-records/TransactionRecords"));
TransactionRecords.preload();

const Inbox = ReactLazyPreload(()=>import("./pages/_mobile/inbox/Inbox"));
Inbox.preload();

const ForgotPassword = ReactLazyPreload(() => import("./pages/_mobile/auth/forgot-password/ForgotPasswordMobile"));
ForgotPassword.preload();

const Turnover = ReactLazyPreload(() => import("./pages/_mobile/turnover/TurnoverMobile"));
Turnover.preload();

export const routes: MobileRouteInfo[] = [
	{
		path: "/register",
		component: Register,
		protected: true,
	},
	{
		path: "/login",
		component: Login,
		protected: true,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		protected: true,
	},
	{
		path: "/player",
		component: MyAccount,
		protected: true,
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		protected: true
	},
	{
		path: "dashboard/:id",
		component: Funds,
		protected: true
	},
	{
		path: "/profile",
		component: PersonalInfo,
		protected: true
	},
	{
		path: "/referralcode",
		component: Referral,
		protected: true
	},
	{
		path: "/casino/:id",
		component: GamePage,
		protected: true,
	},
	{
		path: "/fishing/:id",
		component: GamePage,
		protected: true,
	},
	{
		path: "/slot/:id",
		component: GamePage,
		protected: true,
	},
	{
		path: "/table/:id",
		component: GamePage,
		protected: true,
	},
	{
		path: "/betting-records",
		component: BettingRecords,
		protected: true
	},
	{
		path: "/promo",
		component: Promo,
		protected: true
	},
	{
		path: "/transaction-history",
		component: TransactionRecords,
		protected: true
	},
	{
		path: "/inbox",
		component: Inbox,
		protected: true
	},
	{
		path: "/turnover",
		component: Turnover,
		protected: true
	},
	{
		path: "*",
		component: NoMatch,
	},
];