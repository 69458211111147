import "./GamePanelMobile.less";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Category, gameCategories } from "../../../../../shared/desktop/top-menu/TopMenuProviderList";
import { useAuth } from "../../../../../hooks/auth/AuthContext";
import { useMutation } from "@apollo/client";
import { GET_GAME_URL } from "../../../../desktop/game-page/GamePage.gql";
import useSpinner from "../../../../../hooks/layout/useSpinner";

const GamePanelMobile = () => {
    const { authState } = useAuth();
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const intl = useIntl();
    const [offset, setOffset] = useState(0);
    const [vw, setVw] = useState(0);
    const [sticky, setSticky] = useState(false);
    let locked = false;
    let [i, setI] = useState(0);
    let providerRef: HTMLDivElement | null;
    let gamePanelRef: HTMLDivElement | null;

    const [activeCategory, setActiveCategory] = useState<Category>(gameCategories[0]);
    const [activeProvider, setActiveProvider] = useState<any>(gameCategories[0].provider);
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (gameLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoading])

    const setActiveGamePanel = () => {
        for (let g = 0; g < (gamePanelRef?.children.length ?? 0); g++) {
            gamePanelRef?.children[g].classList.remove('active');
            if (i == g) {
                gamePanelRef?.children[g].classList.add('active');
            }
        }
        let providerTitle = document.getElementById('providerTitle');
        if (providerTitle) {
            providerTitle.innerHTML = intl.formatMessage({ id: gameCategories[i].label });
        }
    }

    const onActiveCategory = (id: string) => {
        i = gameCategories.findIndex(g => g.id === id);
        setI(i);
        // const activeCategory = gameCategories[i];
        // const activeCategory = gameCategories.filter(g => g.id === id)[0];
        // setActiveCategory(activeCategory);
        // setActiveProvider(activeCategory.provider);
        setActiveGamePanel();
    }

    useEffect(() => {
        const N: number = providerRef?.children.length ?? 0;
        let w: any;

        providerRef?.style.setProperty('--n', N.toString()); // calculate total panel 
        providerRef?.style.setProperty('--tx', '0px'); //current panel initial position
        providerRef?.style.setProperty('--i', i.toString()); // first show panel
        providerRef?.style.setProperty('--f', '0'); // resize
        providerRef?.style.setProperty('height', providerRef?.children[i].clientHeight.toString() + 'px');
        // providerRef?.style.setProperty('margin', '12px'); 

        function unify(e: any) { return e.changedTouches ? e.changedTouches[0] : e };

        let x0: any = 0;
        function lock(e: any) {
            x0 = unify(e).clientX;
            locked = true;
            providerRef?.classList.toggle('smooth', !(locked = true));
        };

        function size() { w = window.innerWidth };
        size();

        function move(e: any) {
            if (locked) {
                if (x0 || x0 === 0) {
                    let dx = unify(e).clientX - x0, s: number = Math.sign(dx), f: number = +(s * dx / w).toFixed(2);

                    if ((i > 0 || s < 0) && (i < N - 1 || s > 0)) {
                        providerRef?.style.setProperty('--i', (i -= s).toString());
                        f = 1 - f;
                    }

                    providerRef?.style.setProperty('--tx', '0px');
                    providerRef?.style.setProperty('--f', f.toString());
                    providerRef?.classList.toggle('smooth', !(locked = false));
                    x0 = null;
                    let child = providerRef?.children[i];
                    providerRef?.style.setProperty('height', child?.clientHeight.toString() + 'px');
                    setActiveGamePanel();
                    // setActiveCategory(gameCategories[i]);
                }
            }
        };

        function drag(e: any) {
            e.preventDefault();
            if (locked) {
                if (x0 || x0 === 0)
                    providerRef?.style.setProperty('--tx', `${Math.round(unify(e).clientX - x0)}px`)
            }
        };

        providerRef?.addEventListener('resize', size, false);

        providerRef?.addEventListener('mousedown', lock, false);
        providerRef?.addEventListener('touchstart', lock, false);

        providerRef?.addEventListener('mouseup', move, false);
        providerRef?.addEventListener('touchend', move, false);

        providerRef?.addEventListener('touchmove', e => { e.preventDefault() }, false);

        providerRef?.addEventListener('mousemove', drag, false);
        providerRef?.addEventListener('touchmove', drag, false);

    });

    useEffect(() => {
        const gamePanel = document.getElementById('game-panel-mobile');
        const vw = 100 * Math.max(document.documentElement.clientWidth, window.innerWidth || 0) / 375;

        const onScroll = () => {
            // setOffset(window.pageYOffset);
            // setVw(vw);
            if (window.pageYOffset > vw) {
                gamePanel?.classList.add('sticky');
                setSticky(true);
            }
            else {
                gamePanel?.classList.remove('sticky');
                setSticky(false);
            }
        }

        // clean up code
        // window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            // if (popupWindow) {
            //     popupWindow.location.href = gameData.gameUrl.url;
            window.open(gameData.gameUrl.url, "gameTab");
            // }
        } else {
            // if (popupWindow) {
            //     popupWindow.close(); // eslint-disable-next-line react-hooks/exhaustive-deps
            // }
        }
    }, [gameData]);

    const games: JSX.Element[] = [];
    gameCategories.forEach(element => {
        let providersJSX: JSX.Element[] = [];
        {
            element.provider.map((provider: any) => {
                providersJSX.push(<div key={provider.id} className="provider-item" onClick={() => { onProviderClick(element.gameMenuId, provider) }}>
                    {
                        !provider.imageUrl
                            ? <div className={`provider-mask-icon ${provider.icon}`}></div>
                            : <img className="provider-image" src={provider.imageUrl} alt={intl.formatMessage({ id: provider.label })} />
                    }
                    <div><FormattedMessage id={provider.label} /></div>
                </div>)
            })
        }
        {
            element.provider.length % 4 !== 0 && Array(4 - element.provider.length % 4).fill(1).map((el, i) => {
                providersJSX.push(<div key={`empty-provider-${i}`} className="provider-item"></div>)
            })
        }
        games.push(
            <div key={element.id} className="provider-item-container">
                {providersJSX}
            </div>
        );
    });

    const onProviderClick = (id: string, provider: any) => {
        if (authState.isLogin) {
            if (provider.hasUrl) {
                getGameUrl({
                    variables: {
                        gameUrlInput: {
                            cMSGameMenuProviderId: provider.providerId,
                            platform: 'mobile',
                            lang: 'en-US',
                            isFreeGame: 'false',
                            gameCode: provider.gameCode
                        }
                    }
                });
            } else {
                switch (id) {
                    case 'cl9dpfppm000309lh6mdd25za':
                        navigate(`/home/slot/${provider.providerId}`);
                        break;
                    case 'cl9dpeofm000209lh4cb2hvzw':
                        navigate(`/home/fishing/${provider.providerId}`);
                        break;
                    case 'cl9dpeguc000109lhdke50ipw':
                        navigate(`/home/casino/${provider.providerId}`);
                        break;
                    case 'cl9dpgee3000509lh49smdltg':
                        navigate(`/home/table/${provider.providerId}`);
                        break;
                }
            }
        } else {
            navigate('/home/login')
        }
    }

    return (
        <>
            <div className="game-panel-mobile-container">
                <div ref={node => { gamePanelRef = node }} className={`game-panel-wrapper game-panel-min-height`} >
                    {
                        gameCategories.map((category: Category) => {
                            return <div key={category.id} id={`game-panel-${category.id}`} onClick={() => onActiveCategory(category.id)} className={`game-panel-mobile-item ${category.id === activeCategory.id ? 'active' : ''}`}>
                                <div className={`game-icon ${category.icon}`}></div>
                                <div><FormattedMessage id={category.label} /></div>
                            </div>
                        })
                    }
                </div>
                <div className="provider-container">
                    <div>
                        <h2 id="providerTitle" className="title"><FormattedMessage id={activeCategory.label} /></h2>
                    </div>
                    <div className="provider-wrapper" ref={node => { providerRef = node; }}>
                        {games}
                    </div>

                </div>
            </div>

        </>

    );
};

export default GamePanelMobile;