


import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './LoginModal.less';
import { encode } from 'base-64';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import useSpinner from '../../../../hooks/layout/useSpinner';
import { SET_LOGIN_STATUS, useAuth } from '../../../../hooks/auth/AuthContext';
import { SIGN_IN } from '../../../../pages/desktop/auth/auth.gql';
import { setTokenCookie } from '../../../helpers/set-token-cookie.helper';
import PrimaryButton from '../../Button/PrimaryButton';
import closeIcon from '../../../../assets/images/icon-close.svg';
import userNameIcon from '../../../../assets/images/icon-user.png';
import passwordIcon from '../../../../assets/images/icon-pass.png';
import PhoneInput from '../../phone-input/Phone-Input';

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    signIn: any;
    loading: boolean;
    submitNewPassword?: any;
}

const LoginModal = ({ modalVisible, signIn, loading, submitNewPassword }: Props) => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { authDispatch } = useAuth();
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [countryId, setCountryId] = useState<string>('BD');

    const onFinish = (fieldsValue: any) => {
        if (isResetPassword) {
            let newPassword = Math.floor(Math.random() * 100000000);

            console.log('newPassword', newPassword);

            submitNewPassword({
                variables: {
                    userName: fieldsValue.login_username,
                    phone: fieldsValue.phone,
                    password: encode(newPassword.toString()) ?? ''
                }
            });

            setIsResetPassword(false);
        } else {
            signIn({
                variables: {
                    userName: fieldsValue.login_username,
                    password: encode(fieldsValue.login_password),
                    companyId: process.env.REACT_APP_COMPANY_ID ?? ''
                },
            });
        }
    }

    const registerClick = () => {
        modalVisible.setVisible(false);
        navigate('/home/register');
    }

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    const onClose = ()=> {
        modalVisible.setVisible(false);
        setIsResetPassword(false);
    }

    return (
        <>
            <Modal
                className="login-modal"
                visible={modalVisible.visible}
                closable={false}
                footer={null}
                //onCancel={() => modalVisible.setVisible(false)}
                destroyOnClose
            >
                <>
                    <div className='login-header-container'>
                        <div className="login-title">
                            <FormattedMessage id='LOGIN_WELCOME_TITLE' />
                        </div>
                        <div className='login-close' onClick={onClose}>
                            <div>
                                <img alt={'close'} src={closeIcon} />
                            </div>
                        </div>
                    </div>
                    <Form
                        onFinish={onFinish}
                        form={form}
                        layout="vertical"
                    >
                        <div className='login-form-content-container'>
                            <Form.Item
                                label={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })}
                                name='login_username'
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                                    { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                                ]}
                            >
                                <Input prefix={<img alt={'un'} src={userNameIcon} />} placeholder={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })} />
                            </Form.Item>
                        </div>
                        <div className='login-form-content-container'>
                            {!isResetPassword ?
                                <Form.Item
                                    label={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })}
                                    name='login_password'
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                                        {
                                            pattern: /[A-Za-z0-9]{6,19}$/,
                                            message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                                        }
                                    ]}
                                >
                                    <Input.Password prefix={<img alt={'pa'} src={passwordIcon} />} placeholder={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })} />
                                </Form.Item>
                                :
                                <Form.Item
                                    name="phone"
                                    label={intl.formatMessage({ id: 'REGISTER_PHONE' })}
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: 'PHONE_REQUIRED' }) },
                                        { min: 8, message: intl.formatMessage({ id: 'AUTH_INVALID_PHONE' }) }]}>
                                    <PhoneInput selectCountryId={selectCountryId} />
                                </Form.Item>
                            }
                        </div>
                        <div className='forgot-password-container'>
                            <div className='forgot-password-txt' onClick={() => { setIsResetPassword(!isResetPassword) }}>
                                <FormattedMessage id={isResetPassword ? 'BACK_TO_LOGIN' : 'LOGIN_FORGOT_PASSWORD'} />
                            </div>
                        </div>
                        <PrimaryButton loading={loading} additionalClassName='primary-btn' btnText={isResetPassword ? 'DASHBOARD_MENU_RESET_PASSWORD' : 'LOGIN_LOGIN_BTN'} isFormBtn={true} onClick={() => { }} />
                        <div className='sign-up-container'>
                            <div className='sign-up'><FormattedMessage id='LOGIN_SIGNUP' /></div>
                            <div className='sign-up-link' onClick={registerClick}><FormattedMessage id='LOGIN_SIGNUP_LINK' /></div>
                        </div>
                    </Form>
                </>
            </Modal>
        </>
    )
}

export default LoginModal;