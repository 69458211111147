
import "./SideNavMobile.less";
import "./SideNavListMobile.ts";
import "../../../pages/_mobile/home/home-components/game-panel/GamePanelListMobile";

import { FormattedMessage } from 'react-intl';

interface Props {
    onClick: any;
    liClassName?: any;
    aClassName?: any;
    imgAlt: any;
    imgSrc: any;
    itemName: any;
    component?: any;
}

const SideNavListItem = ({ onClick, liClassName, imgAlt, imgSrc, itemName, component, aClassName }: Props) => {
    return (
        <li className={liClassName ?? ''}>
            <a className={aClassName} onClick={onClick}>
                <img alt={imgAlt} className="menu-icon" src={imgSrc} />
                <FormattedMessage id={itemName} />
            </a>
            {component && component}
        </li>
    );
};

export default SideNavListItem;
