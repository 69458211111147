export const setDBLanguageToGet = (locale: string) => {
    switch (locale) {
        case 'en':
            return 'en';
        case 'zh-CN':
            return 'zh_CN';
        case 'zh-Hant':
            return 'zh_Hant';
        case 'th':
            return 'th';
        case 'ms':
            return 'ms';
        default:
            return 'en';
    }
}