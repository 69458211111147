
import "../AuthMobile.less";
import { Alert, Checkbox, Form, Input, Select, Steps } from "antd";
import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import { SET_LOGIN_STATUS, useAuth } from "../../../../hooks/auth/AuthContext";
import { FormattedMessage, useIntl } from "react-intl";
import useNotification from "../../../../hooks/layout/useNotification";
import PhoneInput from "../../../../shared/desktop/phone-input/Phone-Input";
import PrimaryButtonMobile from "../../../../shared/_mobile/buttons/PrimaryButtonMobile";
import { encode } from "base-64";
import { useMutation } from "@apollo/client";
import { USER_SIGNUP } from "../../../desktop/auth/auth.gql";
import { setTokenCookie } from "../../../../shared/helpers/set-token-cookie.helper";
import useSpinner from "../../../../hooks/layout/useSpinner";
import React from "react";

const { Step } = Steps;

const RegisterMobile = () => {
    const { authState, authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [countryId, setCountryId] = useState<string>('BD');
    const [signUp, { data, loading, error }] = useMutation(USER_SIGNUP, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [affReferralInput, setAffReferralInput] = useState<any>(query.get('refId') ?? "");
    const [authError, setAuthError] = useState<string>();

    useEffect(() => {
        if (loading)
            setLoading(true);
        else
            setLoading(false);
    }, [loading])

    useEffect(() => {
        if (data && data.userSignUp) {
            if (data.userSignUp.status === 'SUCCESS') {
                const { a, r, sid } = data.userSignUp;
                setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'gmf-at', a);
                setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt', r);
                setTokenCookie('gmf-sid', sid);

                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
                navigate('/home');
            } else if (data.userSignUp.status === 'ACCOUNT_EXISTED') {
                setAuthError(intl.formatMessage({ id: 'ERROR_USER_EXISTS' }));
            } else if (data.userSignUp.status === 'AFFILIATEID_REFERRAL_NOT_FOUND') {
                setAuthError(intl.formatMessage({ id: `ERROR_${data?.userSignUp?.status}` }));
            } else if (data.userSignUp.status === 'AGE_LIMIT') {
                setErrorNotification(intl.formatMessage({ id: 'AGE_LIMIT' }));
            } else if (data.userSignUp.status === 'PHONE_EXISTED') {
                setAuthError(intl.formatMessage({ id: 'ERROR_PHONE_EXISTS' }));
            } else {
                setErrorNotification(intl.formatMessage({ id: 'GENERAL_ERROR' }));
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    const currencyList = [
        {
            id: 'CNY',
            label: 'CNY'
        },
        // {
        //     id: 'INR',
        //     label: 'INR'
        // },
        // {
        //     id: 'PKR',
        //     label: 'PKR'
        // },
    ]

    useEffect(() => {
        if (authState && authState.isLogin) {
            navigate('/home/dashboard');
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = () => {
        const variables: any = {
            registerInput: {
                companyId: process.env.REACT_APP_COMPANY_ID ?? '',
                username: form.getFieldValue('reg_username') ?? '',
                password: encode(form.getFieldValue('reg_password')),
                currency: form.getFieldValue('reg_currency') ?? '',
                affiliate: form.getFieldValue('reg_referral') ?? '',
                //affiliate: regStorageJson.affiliate ?? '',
                email: form.getFieldValue('reg_email') ?? '',
                phoneNumber: form.getFieldValue('reg_phone') ?? '',
                //dob: new Date(moment(dobInput).startOf('day').toString()),
                fullName: form.getFieldValue('reg_name') ?? '',
                //dialCode: dialCode,
                //url: window.location.hostname,
                //verificationType: VerificationType.PHONE
            }
        };

        signUp({ variables });
    }

    return (
        <div className="m-auth-container">
            <Form
                onFinish={onFinish}
                form={form}
                initialValues={{ reg_currency: 'CNY', reg_referral: affReferralInput ?? undefined }}
            >
                {authError && <Alert
                    className="auth-error"
                    message={<FormattedMessage id={authError} />}
                    type="error"
                    showIcon
                />}
                <div className='auth-form-content-container-first'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_USERNAME' })}
                        name='reg_username'
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'REGISTER_USERNAME_PLACEHOLDER' })} />
                    </Form.Item>
                </div>
                <div className='auth-form-content-container'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_PASSWORD' })}
                        name='reg_password'
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                            {
                                pattern: /[A-Za-z0-9]{6,19}$/,
                                message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                            }
                        ]}
                    >
                        <Input.Password placeholder={intl.formatMessage({ id: 'REGISTER_PASSWORD_PLACEHOLDER' })} />
                    </Form.Item>
                </div>
                <div className='auth-form-content-container'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_CONFIRM_PASSWORD' })}
                        name='reg_confirm_password'
                        dependencies={['reg_password']}
                        rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('reg_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                            },
                        })]}>
                        <Input.Password placeholder={intl.formatMessage({ id: 'REGISTER_CONFIRM_PASSWORD_PLACEHOLDER' })} />
                    </Form.Item>
                </div>
                <div className='auth-form-content-container-last'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_CURRENCY' })}
                        name='reg_currency'
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                        ]}>
                        <Select
                            className="currency-dropdown"
                            dropdownClassName="currency-dropdown-list"
                            placeholder={intl.formatMessage({ id: 'REGISTER_CURRENCY_PLACEHOLDER' })}
                        //onChange={onChannelChange}
                        >
                            {currencyList?.map((val: any) => {
                                return (
                                    <Select.Option key={val.id} value={val.id}>
                                        <FormattedMessage id={val.label} />
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>

                <div className='auth-form-content-container-first'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_NAME' })}
                        name='reg_name'
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'FULLNAME_REQUIRED' }) },
                            { min: 2, message: intl.formatMessage({ id: 'AUTH_INVALID_FULLNAME' }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'REGISTER_NAME_PLACEHOLDER' })} />
                    </Form.Item>
                </div>
                <div className="auth-form-content-container">
                    <Form.Item
                        name="reg_phone"
                        label={intl.formatMessage({ id: 'REGISTER_PHONE' })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'PHONE_REQUIRED' }) },
                            { min: 8, message: intl.formatMessage({ id: 'AUTH_INVALID_PHONE' }) }]}>
                        <PhoneInput selectCountryId={selectCountryId} />
                    </Form.Item>
                </div>
                <div className='auth-form-content-container'>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_EMAIL' })}
                        name='reg_email'
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'EMAIL_REQUIRED' }) },
                            { type: "email", message: intl.formatMessage({ id: 'AUTH_INVALID_EMAIL' }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'REGISTER_EMAIL_PLACEHOLDER' })} />
                    </Form.Item>
                </div>
                <div className='auth-form-content-container-last' style={{ marginBottom: '15px' }}>
                    <Form.Item
                        label={intl.formatMessage({ id: 'REGISTER_REFERRAL' })}
                        name='reg_referral'
                    // rules={[
                    //     { min: 10, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                    // ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'REGISTER_REFERRAL_PLACEHOLDER' })}
                            disabled={query.get('refId') ? true : false}
                            onChange={e => setAffReferralInput(e.target.value)}
                            value={affReferralInput} />
                    </Form.Item>
                </div>
                {/* <div className="auth-form-captcha-container">
                    <Form.Item
                        className="captcha"
                        label={intl.formatMessage({ id: 'REGISTER_CAPTCHA' })}
                        name='reg_captcha'
                        rules={[
                            // { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'REGISTER_CAPTCHA_PLACEHOLDER' })} />
                    </Form.Item>
                    <div className="captcha-img">img</div>
                </div> */}
                <PrimaryButtonMobile loading={loading} btnText={'REGISTER_TITLE'} isFormBtn={true} onClick={() => { }} />
            </Form>
        </div>
    );
};

export default RegisterMobile;
