
import "./HeaderMobile.less";
import logo from '../../../assets/images/header/logo-mobile.png';
import backBtn from '../../../assets/images/header/icon-back.svg';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetStateAction } from "react";
interface Props {
    setShowSideNav: React.Dispatch<SetStateAction<boolean>>;
    chatVisible: any;
    setChatVisible: any;
}

const HeaderMobile = ({ setShowSideNav, chatVisible, setChatVisible }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onHomeClick = () => {
        navigate('/home');
    }
    const onForumClick = () => {
        navigate('/home');
    }
    const onHelpClick = () => {
        setChatVisible(chatVisible === 'hidden' ? 'maximized' : 'hidden')
    }

    return (
        <>
            {location.pathname === "/home/login" || location.pathname === '/home/register' || location.pathname === '/home/forgot-password' ?
                <div className="header-auth-container">
                    <div className="header-back-btn" onClick={onHomeClick} />
                    <div className="header-auth-txt"><FormattedMessage id={
                        location.pathname === "/home/login" ? 'BTM_NAV_LOGIN' :
                            location.pathname === '/home/forgot-password' ? 'M_MENU_RESET_PASSWORD' : 'BTM_NAV_SIGNUP'} /></div>
                </div> :
                <div className="header-mobile-container">
                    <div className="menu-button" onClick={() => setShowSideNav(true)}>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <img alt={'logo'} onClick={onHomeClick} className='logo' src={logo} />
                    <div className="right-container">
                        {/* <div className="item-column">
                            <div onClick={onForumClick} className='primary-mask-icon icon forum-icon'></div>
                            <div><FormattedMessage id='HEADER_FORUM' /></div>
                        </div>*/}
                        {/* <div className="item-column">
                            <div onClick={onHelpClick} className='primary-mask-icon icon help-icon'></div>
                            <div><FormattedMessage id='HEADER_HELP' /></div>
                        </div> */}
                        {/* <div data-id="b64ea163b3" className="livechat_button item-column">
                            <a href="https://www.livechat.com/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_14761635">
                            </a>
                        </div> */}
                    </div>
                </div>
            }
        </>

    );
};

export default HeaderMobile;
