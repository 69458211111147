
import "./Home.less";
import { useQuery } from '@apollo/client';
import MarqueeComponent from "./home-components/Marquee";
import { GET_BANNER } from "./Home.gql";

import banner1 from '../../../assets/images/banner1.jpg';
import banner2 from '../../../assets/images/banner2.jpg';
import banner3 from '../../../assets/images/banner3.jpg';
import banner4 from '../../../assets/images/banner4.jpg';
import FavoriteCarousels from "./home-components/FavoriteCarousels";
import Banners from "../../../shared/desktop/banners/Banners";
import { useEffect, useState } from "react";

const Home = () => {
    const [mainBanner, setMainBanner] = useState<any>([]);
    const [favBanner, setFavBanner] = useState<any>([]);
    const [favBanner1, setFavBanner1] = useState<any>([]);
    const { data: bannerData } = useQuery(GET_BANNER, {
        variables: { companyId: process.env.REACT_APP_COMPANY_ID ?? '' }, fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (bannerData && bannerData.banners) {
            setMainBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'MAIN') : []);
            setFavBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'FAVOURITE') : []);
            setFavBanner1(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'FAVOURITE').sort((a: any, b: any) => { return b.title.localeCompare(a.title); }) : []);
        }    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerData])


    const marqueeData = {
        announcement: [{
            id: '1',
            message: {
                "cn": "Dear valued customer, please be informed that [mx8898.net ] and [ mx8898.com ] are our alternate URLs, you may use them to access our website as well. ",
                "en": "Dear valued customer, please be informed that [mx8898.net ] and [ mx8898.com ] are our alternate URLs, you may use them to access our website as well. ",
            },
        }]
    }

    return (
        <div className="home-container">
            {/* <Banners bannerData={mainBanner} /> */}
            <MarqueeComponent marqueeData={marqueeData} />
            {/* <FavoriteCarousels bannerData={favBanner} bannerData1={favBanner1} /> */}
        </div>
    );
};

export default Home;
