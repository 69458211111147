
import { Layout } from 'antd';
import '../GeneralFooter.less';

import csIcon from '../../../../assets/images/footer/cs_icon.png';
import ugIcon from '../../../../assets/images/footer/user_guide_icon.png';
import androidDl from '../../../../assets/images/footer/android_download.png';
import { FormattedMessage } from 'react-intl';

const FooterFirstRow = () => {
    return (
        <div className='first-row-container'>
            <div className='item-container'>
                <img alt={'cs'} className='first-row-icons' src={csIcon} />
                <div className='content-container'>
                    <div className='title-txt'><FormattedMessage id='FOOTER_CS' /></div>
                    <div className='content-txt'><FormattedMessage id='FOOTER_CS_CONTENT' /></div>
                    <div></div>
                </div>
            </div>
            <div className='item-container'>
                <img alt={'ug'} className='first-row-icons' src={ugIcon} />
                <div className='content-container'>
                    <div className='title-txt'><FormattedMessage id='FOOTER_USER_GUIDE' /></div>
                    <div className='content-txt'><FormattedMessage id='FOOTER_USER_GUIDE_CONTENT' /></div>
                    <div className='explore-txt' onClick={()=>{window.open('https://www.mx8898.live/', 'window')}}><FormattedMessage id='FOOTER_USER_GUIDE_EXPLORE' /></div>
                </div>
            </div>
            <div className='item-container'>
                <img className='android-dl' alt={'an'} src={androidDl} onClick={()=>{window.open('https://mx8898.com/page/guest/appDownload.jsp', 'window')}} />
            </div>
        </div>
    )
}

export default FooterFirstRow;