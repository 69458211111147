import { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from './pages/desktop/home/Home';
import { Layout, Spin, notification, Modal } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_LOGIN_STATUS, SET_MAINTENANCE_LIST, SET_USER_WALLET, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { routes, RouteInfo } from './routes';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_MAINTENANCE_LIST, GET_USER_PROFILE } from './shared/gql/profile.gql';

import Register from './pages/desktop/auth/Register';
import { forEach } from 'lodash';
import { getDatabase, ref, off, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { LOGOUT } from './pages/desktop/auth/auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookie.helper';
import useSpinner from './hooks/layout/useSpinner';
import GeneralHeader from './shared/desktop/header/GeneralHeader';
import TopMenu from './shared/desktop/top-menu/TopMenu';
import GeneralFooter from './shared/desktop/footer/GeneralFooter';
import csIcon from './assets/images/cs-title-bn.png';
import csIcon2 from './assets/images/cs-247.png';
import logo from './assets/images/header/logo.png';
import whatsappIcon from './assets/images/whatsappsticky.png';
import mailIcon from './assets/images/mailsticky.png';
import fbIcon from './assets/images/fbsticky.png';
import whatsappQR from './assets/images/whatsapp_qr.jpg';
import mailQR from './assets/images/mail_qr.jpg';
import fbQR from './assets/images/facebook_qr.jpg';
import scanImg from './assets/images/scanimg.png';
import { WALLET_BALANCE } from './pages/desktop/home/Home.gql';
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";

const cookies = new Cookies();
const { Content } = Layout;

const AppProtected = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const [collapsed, setCollaped] = useState(window.innerWidth < 769 ? true : false);
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || 'prod-gmf-rt';
    const [showLine, setShowLine] = useState(true);
    const [listener, setListener] = useState<any>();
    const [showLineQr, setShowLineQr] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();

    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'no-cache',
        errorPolicy: "all",
        //notifyOnNetworkStatusChange: true
    });
    const [getMaintenanceList, { data: maintenanceData, error: maintenanceError, called: maintenanceCalled, refetch:
        maintenanceRefetch }] = useLazyQuery(GET_MAINTENANCE_LIST, {
            fetchPolicy: 'no-cache',
        });
    const [getWalletBalance, { data: walletBalanceData, called: walletBalanceCalled, refetch: walletBalanceRefetch }] =
        useLazyQuery(WALLET_BALANCE, { fetchPolicy: 'no-cache', pollInterval: 10000 });
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });

    const onFocus = (isUserLogin: boolean) => {
        if (document.visibilityState === 'visible' && isUserLogin === true) {
            //getUserWallets();
        }
    };

    useEffect(() => {
        if (logoutLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading])

    useEffect(() => {
        //document.addEventListener('visibilitychange', ()=>{onFocus(isLogin)});
        // Calls onFocus when the window first loads
        onFocus(isLogin);
        //maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
    }, []);

    useEffect(() => {
        if (location.pathname !== "/home/dashboard") {
            localStorage.setItem("dashboardTab", '1');
        }
    }, [location.pathname])

    useEffect(() => {
        if (walletBalanceData && walletBalanceData.walletBalance) {
            authDispatch({ type: SET_USER_WALLET, payload: { wallets: null, walletCategory: null, totalBalance: +walletBalanceData.walletBalance.totalBalance, bonusWalletType: null } });
        }
    }, [walletBalanceData])

    useEffect(() => {
        if (authState.isLogin) {
            setIsLogin(true);
            document.addEventListener('visibilitychange', () => { onFocus(true) });
            called && refetch ? refetch() : getUserProfile();
            walletBalanceCalled && walletBalanceRefetch ? walletBalanceRefetch() : getWalletBalance();
            //walletCalled && walletRefetch ? walletRefetch() : getUserWallets();
            maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
        } else {
            if (listener) {
                off(listener);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    //Set user profile is profile data changed
    useEffect(() => {
        if (data && !error) {
            authDispatch({ type: SET_CURRENT_USER, payload: data });
            //firebase
            // if (data?.userProfile && data?.userProfile?.id && authState.isLogin) {
            //     localStorage.setItem("ua", data?.userProfile?.ua);
            //     const firebaseConfig = {
            //         databaseURL: "https://moneyplatformweb-22017-default-rtdb.asia-southeast1.firebasedatabase.app",
            //     };
            //     const app = initializeApp(firebaseConfig);
            //     const db = getDatabase(app);
            //     const listener = ref(db, process.env.REACT_APP_ENV + data?.userProfile?.id);
            //     setListener(listener);
            //     onValue(listener, (snapshot) => {
            //         const firebase = snapshot.val();
            //         const newUa = localStorage.getItem("ua");
            //         if (newUa && firebase.ua) {
            //             if (firebase.ua.toString() != newUa) {
            //                 localStorage.removeItem("ua");
            //                 off(listener);
            //                 logout();
            //             }
            //         }
            //     });
            // }
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (maintenanceData && !maintenanceError) {
            authDispatch({ type: SET_MAINTENANCE_LIST, payload: maintenanceData?.maintenanceList?.providerIdList ?? null });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenanceData, maintenanceError]);

    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    useEffect(() => {
        if (layoutState.showModal && layoutState.showModal.message) {
            switch (layoutState?.showModal.type) {
                case "success":
                    Modal.success({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "error":
                    Modal.error({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "warning":
                    Modal.warning({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "info":
                default:
                    Modal.info({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showModal])

    useEffect(() => {
        if (!cookies.get(refreshTokenKey) && authState.isLogin) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/auth', { replace: true })
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.get(refreshTokenKey)]);

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    const protectedRoutesAccessCheck = (route: RouteInfo) => {
        return (<Suspense fallback={<Home />}>
            <route.component routes={route.routes} />
        </Suspense>);
    }

    const onCsClick = (type: string) => {
        switch (type) {
            case 'whatsapp':
                window.open(`${process.env.REACT_APP_WHATSAPP_CS ?? ''}`, 'window', 'height=500,width=400');
                break;
            case 'mail':
                window.open(`mailto:${process.env.REACT_APP_EMAIL_CS ?? ''}`, 'window', 'height=500,width=400');
                break;
            case 'fb':
                window.open(`${process.env.REACT_APP_FB_CS ?? ''}`, 'window', 'height=500,width=400');
                break;
        }
    }

    const handleNewEvent = (event: EventHandlerPayload<"onNewEvent">) => {
        console.log("LiveChatWidget.onNewEvent", event);
    }

    return (<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} spinning={layoutState.showLoading}>
        <Layout className="app-container">
            <Layout className="site-layout">
                {//authState.isLogin &&
                    <GeneralHeader />
                }
                {//authState.isLogin &&
                    <TopMenu />
                }
                <Content
                    className="site-layout-background">
                    {/* <div className={`cs-stick`}>
                        <div className='cs-stick-icon'>
                            <img className='cs-img' src={csIcon} alt="cs icon" />
                        </div>
                        <div className='cs-stick-container'>
                            <img className='content-icon' src={csIcon2} />
                            <img className='content-logo' src={logo} />
                            <div className='content-items'>
                                <div className='item-container' onClick={() => { onCsClick('whatsapp') }}>
                                    <div className='item-header'>
                                        <div className='item-info'>
                                            <img className='item-icon' src={whatsappIcon} />
                                            <div className='item-name'>Whatsapp</div>
                                        </div>
                                        <img className='item-scan' src={scanImg} />
                                    </div>
                                    <img className='item-qr' src={whatsappQR} />
                                </div>
                                <a className='item-container' href={`mailto:${process.env.REACT_APP_EMAIL_CS ?? ''}`}>
                                    <div className='item-header'>
                                        <div className='item-info'>
                                            <img className='item-icon' src={mailIcon} />
                                            <div className='item-name'>Email</div>
                                        </div>
                                        <img className='item-scan' src={scanImg} />
                                    </div>
                                    <img className='item-qr' src={mailQR} />
                                </a>
                                <div className='item-container' onClick={() => { onCsClick('fb') }}>
                                    <div className='item-header'>
                                        <div className='item-info'>
                                            <img className='item-icon' src={fbIcon} />
                                            <div className='item-name'>Facebook</div>
                                        </div>
                                        <img className='item-scan' src={scanImg} />
                                    </div>
                                    <img className='item-qr' src={fbQR} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <LiveChatWidget
                        license="14761635"
                        visibility={'minimized'}
                        onNewEvent={handleNewEvent}
                    /> */}
                    <Routes>
                        <Route path='' element={<Home />} />
                        <Route path="/referral/*" element={
                            <Suspense fallback={<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} />}>
                                <Register />
                            </Suspense>
                        } />
                        {routes.map((route: RouteInfo, i) => {
                            return (
                                <Route key={i} path={`${route.path}`} element={
                                    protectedRoutesAccessCheck(route)
                                } />
                            );
                        })}
                    </Routes>
                    {//authState.isLogin &&
                        <GeneralFooter />
                    }
                </Content>
            </Layout>
        </Layout>
    </Spin>);
}

export default AppProtected;