import sportBook from '../../../assets/images/sport-book_bdt.png';
import sportExchange from '../../../assets/images/sport-exchange_bdt.png';
import sportVirtual from '../../../assets/images/sport-virtual_bdt.png';
import sportKabaddi from '../../../assets/images/sport-kabaddi_bdt.png';

import providerPt from '../../../assets/images/provider/provider-pt.png';
import providerPp from '../../../assets/images/provider/provider-pp.png';
import providerEvo from '../../../assets/images/provider/provider-evo.png';
import providerAe from '../../../assets/images/provider/provider-ae.png';
import providerEzu from '../../../assets/images/provider/provider-ezu.png';
import providerCQ9 from '../../../assets/images/provider/provider-cq9.png';
import providerJili from '../../../assets/images/provider/provider-jili.png';
import providerFc from '../../../assets/images/provider/provider-fc.png';
import providerJdb from '../../../assets/images/provider/provider-jdb.png';
import providerKa from '../../../assets/images/provider/provider-ka.png';
import providerPg from '../../../assets/images/provider/provider-pg.png';
import providerSg from '../../../assets/images/provider/provider-sg.png';
import providerBpoker from '../../../assets/images/provider/provider-bpoker-mobile.png';
import providerLudo from '../../../assets/images/provider/provider-ludo-mobile.png';
import providerVenusMobile from '../../../assets/images/provider/provider-venus-mobile.png';
import providerNumber from '../../../assets/images/provider/provider-number-mobile.png';
import providerNetent from '../../../assets/images/provider/provider-netent.png';
import providerNetentMobile from '../../../assets/images/provider/provider-netent-mobile.png';
import providerRt from '../../../assets/images/provider/provider-rt.png';
import providerRtMobile from '../../../assets/images/provider/provider-rt-mobile.png';
import providerKm from '../../../assets/images/provider/provider-km-mobile.png';
import providerVenus from '../../../assets/images/provider/provider-venus.png';

import gameCasino from '../../../assets/images/game/icon-casino-mobile.svg';
import gameSlot from '../../../assets/images/game/icon-slot-mobile.svg';
import gameSport from '../../../assets/images/game/icon-sport-mobile.svg';
import gameFish from '../../../assets/images/game/icon-fish-mobile.svg';
import gameLottery from '../../../assets/images/game/icon-lottery-mobile.svg';
import gameTable from '../../../assets/images/game/icon-table-mobile.svg';

export interface Category {
    id: string;
    label: string;
    icon: string;
    imageUrl: string;
    gameMenuId: string;
    provider: any[];
}

export const gameCategories: Category[] = [
    {
        id: 'sports',
        label: 'GAME_SPORTS',
        icon: 'sports-icon',
        imageUrl: gameSport,
        gameMenuId: 'cl9dpdo0w000009lhaw4k3dfp',
        provider: [
            {
                id: "sportsexchange",
                d_label: "sportsexchange",
                url: "",
                hasUrl: true,
                logo: sportExchange,
                gameCode: "",
                providerId: 'clb0sx7en000008l34op8f4ek', //for maintenance checking,
                specialTag: '',
                label: 'PROVIDER_SPORTS_EXCHANGE',
                icon: 'exchange-icon',
            },
            {
                id: "sportsbook",
                d_label: "sportsbook",
                url: "",
                hasUrl: true,
                logo: sportBook,
                gameCode: "sbs_sportsbook",
                providerId: 'cl9v3jtkc000009l3d8gyaopp', //for maintenance checking,
                specialTag: '',
                label: 'PROVIDER_SPORTS_SPORTSBOOK',
                icon: 'sportbook-icon',
            },
            // {
            //     id: "sportsvirtual",
            //     d_label: "sportsvirtual",
            //     url: "",
            //     hasUrl: true,
            //     logo: sportVirtual,
            //     gameCode: "",
            //     providerId: 'sportsvirtual', //for maintenance checking,
            //     specialTag: '',
            //     label: 'PROVIDER_SPORTS_VIRTUAL',
            //     icon: 'virtual-icon',
            // },
            // {
            //     id: "sportkabaddi",
            //     d_label: "sportkabaddi",
            //     url: "",
            //     hasUrl: true,
            //     logo: sportKabaddi,
            //     gameCode: "",
            //     providerId: 'sportkabaddi', //for maintenance checking,
            //     specialTag: '',
            //     label: 'PROVIDER_SPORTS_KABADDI',
            //     icon: 'kabaddi-icon',
            // },
        ]
    },
    {
        id: 'casino',
        label: 'GAME_CASINO',
        icon: 'casino-icon',
        imageUrl: gameCasino,
        gameMenuId: 'cl9dpeguc000109lhdke50ipw',
        provider: [
            {
                id: 'casinopt',
                providerId: 'cl9ebcxcr000109mjb81g6z6r',
                d_label: 'PROVIDER_CASINO_PT',
                logo: providerPt,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_CASINO_PT',
                imageUrl: providerPt,
                icon: 'pt-icon',
            },
            {
                id: 'casinopp',
                providerId: 'cl9ebafnt000009mj9c4ld0wh',
                d_label: 'PROVIDER_CASINO_PP',
                logo: providerPp,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_CASINO_PP',
                imageUrl: providerPp,
                icon: 'pp-icon',
            },
            {
                id: 'casinoevo',
                providerId: 'cl9dpldn3000609lhetsdfcok',
                d_label: 'PROVIDER_CASINO_EVO',
                logo: providerEvo,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_CASINO_EVO',
                imageUrl: providerEvo,
                icon: 'evo-icon',
            },
            // {
            //     id: 'casinoae',
            //     providerId: 'ae',
            //     d_label: 'PROVIDER_CASINO_AE',
            //     logo: providerAe,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_CASINO_AE',
            //     imageUrl: providerAe,
            //     icon: 'ae-icon',
            // },
            // {
            //     id: 'casinoezu',
            //     providerId: 'ezu',
            //     d_label: 'PROVIDER_CASINO_EZU',
            //     logo: providerEzu,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_CASINO_EZU',
            //     imageUrl: providerEzu,
            //     icon: 'ezugi-icon',
            // },
            // {
            //     id: 'casinovenus',
            //     providerId: 'venus',
            //     d_label: 'PROVIDER_CASINO_VENUS',
            //     logo: providerVenus,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_CASINO_VENUS',
            //     imageUrl: providerVenusMobile,
            //     icon: 'awcmvenus-icon',
            // },
        ]
    },
    {
        id: 'fishing',
        label: 'GAME_FISHING',
        icon: 'fishing-icon',
        imageUrl: gameFish,
        gameMenuId: 'cl9dpeofm000209lh4cb2hvzw',
        provider: [
            {
                id: 'fishpp',
                providerId: 'cl9ebiufw000209mj37cyg86z',
                d_label: 'PROVIDER_FISH_PP',
                logo: providerPp,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_FISH_PP',
                imageUrl: providerPp,
                icon: 'cq9-icon',
            },
            // {
            //     id: 'fishcq9',
            //     providerId: 'cq9',
            //     d_label: 'PROVIDER_FISH_CQ9',
            //     logo: providerCQ9,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_FISH_CQ9',
            //     imageUrl: providerCQ9,
            //     icon: 'cq9-icon',
            // },
            // {
            //     id: 'fishjili',
            //     providerId: 'jili',
            //     d_label: 'PROVIDER_FISH_JILI',
            //     logo: providerJili,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_FISH_JILI',
            //     imageUrl: providerJili,
            //     icon: 'jili-icon',
            // },
            // {
            //     id: 'fishfc',
            //     providerId: 'fc',
            //     d_label: 'PROVIDER_FISH_FC',
            //     logo: providerFc,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_FISH_FC',
            //     imageUrl: providerFc,
            //     icon: 'fc-icon',
            // },
            // {
            //     id: 'fishjsb',
            //     providerId: 'jdb',
            //     d_label: 'PROVIDER_FISH_JDB',
            //     logo: providerJdb,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_FISH_JDB',
            //     imageUrl: providerJdb,
            //     icon: 'jdb-icon',
            // },
            // {
            //     id: 'fishka',
            //     providerId: 'ka',
            //     d_label: 'PROVIDER_FISH_KA',
            //     logo: providerKa,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_FISH_KA',
            //     imageUrl: providerKa,
            //     icon: 'ka-icon',
            // },
        ]
    },
    // {
    //     id: 'lottery',
    //     label: 'GAME_LOTTERY',
    //     icon: 'lottery-icon',
    //     imageUrl: gameLottery,
    //     gameMenuId: 'cl9dpg16p000409lhb35o53nu',
    //     provider: [
    //         {
    //             id: 'number',
    //             label: 'PROVIDER_LOTTERY_NUMBER',
    //             imageUrl: providerNumber,
    //             icon: 'number-icon',
    //             gameUrl: '',
    //         },
    //     ]
    // },
    {
        id: 'slot',
        label: 'GAME_SLOT',
        icon: 'slot-icon',
        imageUrl: gameSlot,
        gameMenuId: 'cl9dpfppm000309lh6mdd25za',
        provider: [
            // {
            //     id: 'slotcq9',
            //     providerId: 'cq9',
            //     d_label: 'PROVIDER_SLOT_CQ9',
            //     logo: providerCQ9,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_SLOT_CQ9',
            //     imageUrl: providerCQ9,
            //     icon: 'cq9-icon',
            // },
            // {
            //     id: 'slotjili',
            //     providerId: 'jili',
            //     d_label: 'PROVIDER_SLOT_JILI',
            //     logo: providerJili,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_SLOT_JILI',
            //     imageUrl: providerJili,
            //     icon: 'jili-icon',
            // },
            // {
            //     id: 'slotjdb',
            //     providerId: 'jdb',
            //     d_label: 'PROVIDER_SLOT_JDB',
            //     logo: providerJdb,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_SLOT_JDB',
            //     imageUrl: providerJdb,
            //     icon: 'jdb-icon',
            // },
            // {
            //     id: 'slotpg',
            //     providerId: 'pg',
            //     d_label: 'PROVIDER_SLOT_PG',
            //     logo: providerPg,
            //     url: "",
            //     hasUrl: false,
            //     providerNameClassName: 'provider-name',
            //     specialTag: '',
            //     label: 'PROVIDER_SLOT_PG',
            //     imageUrl: providerPg,
            //     icon: 'pg-icon'
            // },
            {
                id: 'slotpt',
                providerId: 'cl9ebwh93000409mob82pe1jx',
                d_label: 'PROVIDER_SLOT_PT',
                logo: providerPt,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_PT',
                imageUrl: providerPt,
                icon: 'pt-icon',
            },
            {
                id: 'slotssg',
                providerId: 'cl9ebya1t000609mo4e0meqxz',
                d_label: 'PROVIDER_SLOT_SSG',
                logo: providerSg,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_SSG',
                imageUrl: providerSg,
                icon: 'sg-icon',
            },
            {
                id: 'slotgz',
                providerId: 'cl9ebvlxa000309moc5x301eg',
                d_label: 'PROVIDER_SLOT_GAMZIX',
                logo: providerFc,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_GAMZIX',
                imageUrl: providerFc,
                icon: 'fc-icon',
            },
            {
                id: 'slotpp',
                providerId: 'cl9ebv0ag000209mo7v4a3vby',
                d_label: 'PROVIDER_SLOT_PP',
                logo: providerPp,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_PP',
                imageUrl: providerPp,
                icon: 'pp-icon',
            },
            {
                id: 'slotpng',
                providerId: 'cl9ebto6t000109modnhu78fn',
                d_label: 'PROVIDER_SLOT_PLAYNGO',
                logo: providerKa,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_PLAYNGO',
                imageUrl: providerKa,
                icon: 'ka-icon',
            },
            {
                id: 'slotnetent',
                providerId: 'cl9ebsceb000009mo60jtgklk',
                d_label: 'PROVIDER_SLOT_NETENT',
                logo: providerNetent,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_NETENT',
                imageUrl: providerNetentMobile,
                icon: 'rt-icon',
            },
            {
                id: 'slotrt',
                providerId: 'cl9ebl2uo000309mjaru0cl5h',
                d_label: 'PROVIDER_SLOT_RT',
                logo: providerRt,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
                label: 'PROVIDER_SLOT_RT',
                imageUrl: providerRtMobile,
                icon: 'rt-icon',
            },
        ]
    },
    {
        id: 'table',
        label: 'GAME_TABLE',
        icon: 'table-icon',
        imageUrl: gameTable,
        gameMenuId: 'cl9dpgee3000509lh49smdltg',
        provider: [
            {
                id: 'tablepng',
                providerId: 'cl9eh0vmm000009lddfcb5qqn',
                label: 'PROVIDER_TABLE_PLAYNGO',
                imageUrl: providerKm,
                icon: 'km-icon',
                gameUrl: '',
                d_label: 'PROVIDER_TABLE_PLAYNGO',
                logo: providerRt,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
            },
            {
                id: 'tablept',
                providerId: 'cl9eh5xcr000209ldho3ig9jg',
                label: 'PROVIDER_TABLE_PT',
                imageUrl: providerPt,
                icon: 'jili-icon',
                gameUrl: '',
                d_label: 'PROVIDER_TABLE_PT',
                logo: providerRt,
                url: "",
                hasUrl: false,
                providerNameClassName: 'provider-name',
                specialTag: '',
            },
            // {
            //     id: 'km',
            //     label: 'PROVIDER_TABLE_KM',
            //     imageUrl: providerKm,
            //     icon: 'km-icon',
            //     gameUrl: '',
            // },
            // {
            //     id: 'jili',
            //     label: 'PROVIDER_TABLE_JILI',
            //     imageUrl: providerJili,
            //     icon: 'jili-icon',
            //     gameUrl: '',
            // },
            // {
            //     id: 'ka',
            //     label: 'PROVIDER_TABLE_KA',
            //     imageUrl: providerKa,
            //     icon: 'ka-icon',
            //     gameUrl: '',
            // },
            // {
            //     id: 'cq9',
            //     label: 'PROVIDER_TABLE_CQ9',
            //     imageUrl: providerCQ9,
            //     icon: 'cq9-icon',
            //     gameUrl: '',
            // },
            // {
            //     id: 'bpoker',
            //     label: 'PROVIDER_TABLE_BPOKER',
            //     imageUrl: providerBpoker,
            //     icon: 'bpoker-icon',
            //     gameUrl: '',
            // },
            // {
            //     id: 'ludo',
            //     label: 'PROVIDER_TABLE_LUDO',
            //     imageUrl: providerLudo,
            //     icon: 'ludo-icon',
            //     gameUrl: '',
            // },
        ]
    },
];