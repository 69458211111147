
import "./HomeMobile.less";

import { useAuth } from "../../../hooks/auth/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_BANNER } from "../../desktop/home/Home.gql";
import BannerMobile from "./home-components/banner/BannerMobile";
import banner1 from '../../../assets/images/banner1.jpg';
import banner2 from '../../../assets/images/banner2.jpg';
import banner3 from '../../../assets/images/banner3.jpg';
import banner4 from '../../../assets/images/banner4.jpg';
import MarqueeMobile from "./home-components/marquee/MarqueeMobile";
import GamePanelMobile from "./home-components/game-panel/GamePanelMobile";
import FavouriteMobile from "./home-components/favourite/FavouriteMobile";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import cuid from "cuid";

interface Props {
    setLiveChatId: Dispatch<SetStateAction<string>>;
}

const HomeMobile = ({ setLiveChatId }: Props) => {
    const [mainBanner, setMainBanner] = useState<any>([]);
    const [favBanner, setFavBanner] = useState<any>([]);
    const { data: bannerData } = useQuery(GET_BANNER, {
        variables: { companyId: process.env.REACT_APP_COMPANY_ID ?? '' }, fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (bannerData && bannerData.banners) {
            setLiveChatId(cuid());
            setMainBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'MAIN') : []);
            setFavBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'FAVOURITE') : []);
        }    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerData])

    const marqueeData = {
        announcement: [{
            id: '1',
            message: {
                "cn": "Dear valued customer, please be informed that [mx8898.net ] and [ mx8898.com ] are our alternate URLs, you may use them to access our website as well. ",
                "en": "Dear valued customer, please be informed that [mx8898.net ] and [ mx8898.com ] are our alternate URLs, you may use them to access our website as well. ",
            },
        }]
    }

    return (
        <div className="home-mobile-container">
            {/* <BannerMobile bannerData={mainBanner} /> */}
            <MarqueeMobile marqueeData={marqueeData} />
            <GamePanelMobile />
            {/* <FavouriteMobile bannerData={favBanner} /> */}
        </div>
    );
};

export default HomeMobile;
