import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
	path: string;
	component: any;
	routes?: RouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const Register = ReactLazyPreload(() => import("./pages/desktop/auth/Register"));
Register.preload();

// const CasinoGames = ReactLazyPreload(() => import("./pages/desktop/game-page/game-type/CasinoGamePage"));
// CasinoGames.preload();

// const FishGames = ReactLazyPreload(() => import("./pages/desktop/game-page/game-type/FishingGamePage"));
// FishGames.preload();

// const SlotGames = ReactLazyPreload(() => import("./pages/desktop/game-page/game-type/SlotGamePage"));
// SlotGames.preload();

const GamePage = ReactLazyPreload(() => import("./pages/desktop/game-page/GamePage"));
GamePage.preload();

const Dashboard = ReactLazyPreload(() => import("./pages/desktop/dashboard/Dashboard"));
Dashboard.preload();

const TncInfo = ReactLazyPreload(() => import("./pages/desktop/tnc-info/TNCInfo"));
TncInfo.preload();

const Promo = ReactLazyPreload(() => import("./pages/desktop/promo/Promo"));
Promo.preload();

export const routes: RouteInfo[] = [
	{
		path: "/register",
		component: Register,
		protected: true,
	},
	{
		path: "/casino",
		component: GamePage,
		protected: true,
	},
	{
		path: "/fishing",
		component: GamePage,
		protected: true,
	},
	{
		path: "/slot",
		component: GamePage,
		protected: true,
	},
	{
		path: '/table',
		component: GamePage,
		protected: true,
	},
	{
		path: "/dashboard/:id",
		component: Dashboard,
		protected: true,
	},
	{
		path: "/terms/:id",
		component: TncInfo,
		protected: true,
	},
	{
		path: "/promo",
		component: Promo,
		protected: true,
	},
	{
		path: "*",
		component: NoMatch,
	},
];