
import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import closeIcon from '../../../../assets/images/icon-close.svg';
import { FormattedMessage } from 'react-intl';
import { forEach } from 'lodash';
import './LanguageModal.less';
import { SET_LANGUAGE, useLayout } from '../../../../hooks/layout/LayoutContext';
import { languageList } from './LanguageList';

interface Props {
    modalVisible: boolean;
    setModalVisible: any;
}

const LanguageModal = ({ modalVisible, setModalVisible }: Props) => {
    const { layoutState, layoutDispatch } = useLayout();
    const [localLangList, setLocalLangList] = useState(languageList);

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }

        let newLangList = [...localLangList];

        forEach(newLangList, (currency) => {
            forEach(currency.language, (lang) => {
                if (lang.id === language) {
                    lang.selected = true
                } else {
                    lang.selected = false
                }
            })
        });

        setLocalLangList(newLangList);

    }, [])

    const onChangeLangClick = (language: string) => {
        localStorage.setItem('pfxl', language);
        let newLangList = [...localLangList];

        forEach(newLangList, (currency) => {
            forEach(currency.language, (lang) => {
                if (lang.id === language) {
                    lang.selected = true
                } else {
                    lang.selected = false
                }
            })
        });

        setLocalLangList(newLangList);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        setModalVisible(false);
    }

    return (
        <>
            <Modal
                className="language-modal"
                visible={modalVisible}
                closable={false}
                footer={null}
                onCancel={() => setModalVisible(false)}
                destroyOnClose
            >
                <>
                    <div className='language-header-container'>
                        <div className="language-title">
                            <FormattedMessage id='LANGUAGE_TITLE' />
                        </div>
                        <div className='language-close' onClick={() => { setModalVisible(false) }}>
                            <div>
                                <img alt={'close'} src={closeIcon} />
                            </div>
                        </div>
                    </div>
                    <div className='modal-content-container'>
                        {localLangList?.map((currency) => {
                            return (
                                <div key={currency?.id} className='language-item-container'>
                                    <img className='language-icon' src={currency?.icon} />
                                    <div className='currency-name'>{currency?.name}</div>
                                    {currency?.language.map((lang) => {
                                        return (
                                            <div key={lang?.id} className='language-container'>
                                                <div
                                                    className={lang?.selected ? 'language-name-selected' : 'language-name'}
                                                    onClick={() => { onChangeLangClick(lang?.id) }}
                                                >
                                                    {lang?.name}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })
                        }
                    </div>
                </>
            </Modal>
        </>
    )
}

export default LanguageModal;