
import "./SideNavMobile.less";
import "./SideNavListMobile.ts";
import "../../../pages/_mobile/home/home-components/game-panel/GamePanelListMobile";

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { SetStateAction, useState, useRef, useEffect } from "react";
import { useIntl } from 'react-intl';
import homeIcon from '../../../assets/images/sidenav/icon-home.svg';
import promoIcon from '../../../assets/images/sidenav/icon-promotion.svg';
import vipIcon from '../../../assets/images/sidenav/icon-vip.svg';
import csIcon from '../../../assets/images/sidenav/icon-member-talk.svg';
import affIcon from '../../../assets/images/sidenav/icon-affiliate.svg';
import SideNavListItem from "./SideNavListItem";
import { SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import { languageList } from "../../desktop/modal/LanguageModal/LanguageList";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { forEach } from "lodash";
import { useMutation } from "@apollo/client";
import { GET_GAME_URL } from "../../../pages/desktop/game-page/GamePage.gql";
import { gameCategories } from "../../desktop/top-menu/TopMenuProviderList";
import useSpinner from "../../../hooks/layout/useSpinner";

interface Props {
    showSideNav: boolean;
    setShowSideNav: React.Dispatch<SetStateAction<boolean>>;
}

const SideNavMobile = ({ showSideNav, setShowSideNav }: Props) => {
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const intl = useIntl();
    const sideNavRef = useRef(null);
    const [active, setActive] = useState<string>("SIDE_NAV_HOME");
    const { authState } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const [localLangList, setLocalLangList] = useState(languageList);
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (gameLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [gameLoading]);

    const closeNav = (e: any) => {
        if (e.target.classList.contains('side-nav-active')) {
            // setActive("SIDE_NAV_HOME");
            setShowSideNav(false);
        }
    }
    useEffect(() => {
        window?.addEventListener('mouseup', closeNav, false);
    }, []);

    const navHome = () => {
        setShowSideNav(false);
        setActive("");
        navigate('/home');
    }

    const changeLang = (language: string) => {
        localStorage.setItem('pfxl', language);
        let newLangList = [...localLangList];

        forEach(newLangList, (currency) => {
            forEach(currency.language, (lang) => {
                if (lang.id === language) {
                    lang.selected = true
                } else {
                    lang.selected = false
                }
            })
        });

        setLocalLangList(newLangList);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        setShowSideNav(false);
    }

    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            window.open(gameData.gameUrl.url, "gameTab");
        }
    }, [gameData]);


    const onProviderClick = (id: string, provider: any) => {
        if (authState.isLogin) {
            if (provider.hasUrl) {
                getGameUrl({
                    variables: {
                        gameUrlInput: {
                            cMSGameMenuProviderId: provider.providerId,
                            platform: 'mobile',
                            lang: 'en-US',
                            isFreeGame: 'false',
                            gameCode: provider.gameCode
                        }
                    }
                });
            } else {
                console.log('test', id);
                switch (id) {
                    case 'cl9dpfppm000309lh6mdd25za':
                        navigate(`/home/slot/${provider.providerId}`);
                        break;
                    case 'cl9dpeofm000209lh4cb2hvzw':
                        navigate(`/home/fishing/${provider.providerId}`);
                        break;
                    case 'cl9dpeguc000109lhdke50ipw':
                        navigate(`/home/casino/${provider.providerId}`);
                        break;
                    case 'cl9dpgee3000509lh49smdltg':
                        navigate(`/home/table/${provider.providerId}`);
                        break;
                }
            }
        } else {
            setActive("SIDE_NAV_HOME");
            navHome();
        }

        setShowSideNav(false);
    }

    return (
        <aside className={`side-nav-container ${showSideNav ? "active" : ""}`}>
            <div className="side-nav-bg"></div>
            <nav className={`side-nav ${showSideNav ? "side-nav-active" : ""}`} ref={sideNavRef}>
                {/* <a href="javascript:void(0)" className="close" onClick={() => closeNav}>&times;</a> */}
                <ul className="menu">
                    <SideNavListItem onClick={() => { navHome() }} imgAlt={'home'} imgSrc={homeIcon} itemName={'HOME_PAGE'} />
                    {gameCategories.map((menu: any) => {
                        return <SideNavListItem key={menu?.id} onClick={() => setActive(menu.label)} imgAlt={intl.formatMessage({ id: menu.label })}
                            imgSrc={menu.imageUrl} aClassName={active === menu.label ? "active" : ""} itemName={menu.label} component={
                                menu.provider != null &&
                                <ul className={`sub-menu ${active === menu.label ? "active" : ""}`}>
                                    {menu.provider.length > 0 &&
                                        menu.provider.map((sm: any) => {
                                            return <li key={sm?.id} onClick={() => { onProviderClick(menu.gameMenuId, sm) }}>
                                                <div className="provider-container">
                                                    {menu.id === 'sports' ?
                                                        <div className={sm.icon}></div> :
                                                        <div className="img-container"><img alt={intl.formatMessage({ id: sm.label })} className="submenu-icon" src={sm.imageUrl} /></div>
                                                    }
                                                    <FormattedMessage id={sm.label} />
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            } />
                    })}
                    <SideNavListItem onClick={() => { navigate('/home/promo') }} liClassName={'menu-w-gap'} imgAlt={'promo'} imgSrc={promoIcon} itemName={'M_PROMO_PAGE'} />
                    <SideNavListItem onClick={() => { }} imgAlt={'vip'} imgSrc={vipIcon} itemName={'M_VIP_PAGE'} />
                    <li>
                        <div className="menu-btm-container">
                            <div className="menu-btm">
                                <div className="menu-btm-icon">
                                    <img alt={'cs'} src={csIcon} />
                                </div>
                                <div className="menu-content-container">
                                    <div className="menu-content-title"><FormattedMessage id='M_CS_TITLE' /></div>
                                    <div className="menu-content-txt"><FormattedMessage id='M_CS_CONTENT' /></div>
                                </div>
                            </div>
                            <div className="menu-btm">
                                <div className="menu-btm-icon">
                                    <img alt={'cs'} src={affIcon} />
                                </div>
                                <div className="menu-content-container">
                                    <div className="menu-content-title"><FormattedMessage id='M_AFF' /></div>
                                </div>
                            </div>
                            {
                                authState?.isLogin &&
                                <div className="menu-lang">
                                    {localLangList?.map((currency) => {
                                        return (
                                            <div key={currency?.id} className='currency-lang-container'>
                                                {currency?.language.map((lang) => {
                                                    return (
                                                        <div key={lang?.id} className={lang?.selected ? "lang-container-selected" : "lang-container"} onClick={() => { changeLang(lang?.id) }}>
                                                            <img alt={'lang'} className='language-img' src={lang?.icon} />
                                                            <div className={lang?.selected ? "lang-name-selected" : "lang-name"}>{lang?.name}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            }
                        </div>
                    </li>
                </ul>
            </nav>

        </aside>
    );
};

export default SideNavMobile;
