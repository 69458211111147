import langEnLogo from '../../../../assets/images/header/lang_en.png';
import langBnLogo from '../../../../assets/images/header/lang_bn.png';
import langCnLogo from '../../../../assets/images/header/lang_cn.png';

export const languageList = [
    {
        id: 'zh-CN',
        icon: langCnLogo,
        name: 'CNY',
        language: [
            {
                id: 'en',
                name: 'English',
                icon: langEnLogo,
                selected: true
            },
            {
                id: 'zh-CN',
                name: '中文',
                icon: langCnLogo,
                selected: false
            }
        ]
    }
]