
import { Layout } from 'antd';
import './GeneralHeader.less';
import HeaderFirstRow from './HeaderElements/first-row/HeaderFirstRow';
import HeaderSecondRow from './HeaderElements/second-row/HeaderSecondRow';

const { Header } = Layout;

const GeneralHeader = () => {
  return (
    <Header className="site-layout-header" >
      <HeaderFirstRow />
      <HeaderSecondRow />
    </Header>
  )
}

export default GeneralHeader;