import { gql } from '@apollo/client';


export const GET_BANNER = gql`
query Banners($companyId:String!){
    banners(companyId:$companyId){
      id,
      title,
      pictureUrl,
      type
    }
  }
`;

export const WALLET_BALANCE = gql`
query WalletBalance{
  walletBalance{
    totalBalance
  }
}
`;