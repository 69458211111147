
import { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import Marquee from "react-fast-marquee";
import AnnouncementIcon from '../../../../assets/images/icon-announcement.svg';
import { useLayout } from "../../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../../shared/helpers/language.helpers";

const MarqueeComponent = ({ marqueeData }: any) => {
    const { layoutState } = useLayout();

    return (
        <>
            <div className="home-marquee-container">
                <div className="home-marquee">
                    <div className="marquee-icon"/>
                    {/* <img alt={'announcement'} src={AnnouncementIcon} /> */}
                    <Marquee
                        speed={40}
                        pauseOnHover
                        className='home-marquee-item'
                    >
                        {
                            marqueeData.announcement.map((marquee: {
                                id: string; message: { [x: string]: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; };
                            }) => {
                                return <div key={marquee.id} className="home-marquee-content">
                                    {marquee.message[setDBLanguageToGet(layoutState.locale)]}
                                </div>
                            })
                        }
                    </Marquee>
                </div>
            </div>
        </>
    )
}

export default MarqueeComponent;