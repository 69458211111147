import { gql } from "@apollo/client";

export const GET_GAME_URL = gql`
mutation GameUrl($gameUrlInput:GameUrlInput!){
    gameUrl(gameUrlInput:$gameUrlInput){
        url
    }
}
`

export const GET_SLOT_GAME_LIST = gql`
query SlotGameList($slotGameListInput:SlotGameListInput!){
    slotGameList(slotGameListInput:$slotGameListInput){
        gameCategory,
        gameList
    }
}
`

export const GET_FISH_GAME_LIST = gql`
query FishGameList($fishGameListInput:FishGameListInput!){
    fishGameList(fishGameListInput:$fishGameListInput){
        gameList
    }
}
`

export const GET_GAME_LIST = gql`
query GameList($gameListInput:GameListInput!){
    gameList(gameListInput:$gameListInput){
        gameCategory,
        gameList
    }
}
`
