
import './FooterMobile.less';
import logo from '../../../assets/images/header/logo-mobile.png';
import { paymentMethods, responsible, community, license, PaymentMethod, Responsible, Community, License } from './FooterListMobile';

import { useNavigate } from 'react-router-dom';
import { Col, Divider, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

const FooterMobile = () => {
    return (
        <div className="footer-mobile-container">
            <div className="payment-container">
                <div className="text-primary"><FormattedMessage id="FOOTER_PAYMENT" /></div>
                <div className="footer-wrapper">
                    {
                        paymentMethods.map((pm: PaymentMethod) => {
                            return <a key={pm?.id} href={pm.url}><img className="footer-icon payment-icon" src={pm.icon} alt={pm.label} /></a>
                        })
                    }
                </div>
            </div>
            <div>
                <Row className="row" gutter={[24, 24]} wrap={true}>
                    <Col className="gutter-row responsible-container" span={12}>
                        <div className="text-primary"><FormattedMessage id="FOOTER_RESPONSIBLE" /></div>
                        <div className="footer-wrapper">
                            {
                                responsible.map((r: Responsible) => {
                                    return <a key={r?.id} href={r.url}><img className="footer-icon community-icon" src={r.icon} alt={r.label} /></a>
                                })
                            }
                        </div>
                    </Col>
                    <Col className="gutter-row commnunity-container" span={12}>
                        <div className="text-primary"><FormattedMessage id="FOOTER_COMMUNITY" /></div>
                        <div className="footer-wrapper">
                            {
                                community.map((c: Community) => {
                                    return <a key={c?.id} href={c.url} target="_blank"><img className="footer-icon community-icon" src={c.icon} alt={c.label} /></a>
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <Divider className="divider footer-divider" />
            <div className="commnunity-container">
                <div className="text-primary"><FormattedMessage id="FOOTER_LICENSE" /></div>
                <div className="footer-wrapper">
                    {
                        license.map((l: License) => {
                            return <a key={l?.id} href={l.url}><img className="license-icon" src={l.icon} alt={l.label} /></a>
                        })
                    }
                </div>
            </div>
            <Divider className="divider footer-divider" />
            {/* <div className="footer-content">
                <div className='footer-title'><FormattedMessage id="FOOTER_ABOUT" /></div>
                <p><FormattedMessage id="FOOTER_ABOUT_CONTENT_1" /></p>
                <p><FormattedMessage id="FOOTER_ABOUT_CONTENT_3" /></p>
                <p><FormattedMessage id="FOOTER_ABOUT_CONTENT_4" /></p>
            </div> */}
            <Divider className="divider footer-divider" />
            <div className="copyright-container">
                <div className="copyright-logo"><img className="logo" src={logo} alt="logo" /></div>
                <div className='copyright-content'>
                    <div className="text-primary"><FormattedMessage id="FOOTER_BEST_QUALITY_PLATFORM" /></div>
                    <div className='copyright-year'><FormattedMessage id="FOOTER_COPYRIGHT" values={{
                        year: new Date().getFullYear().toString()
                    }} /></div>
                </div>
            </div>
        </div>

    )
}

export default FooterMobile;